@import 'variables.scss';
@import 'fonts/gt-walsheim-pro/stylesheet.css';
// @import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap.scss';
@import 'navbar.scss';
@import 'buttons.scss';

*:focus {
  outline: none !important;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-body {
  background-color: $body-bg !important;
}

.bordered-box {
  border: 25px solid transparent;
  // border-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384.219 333.859' width='320'><path fill='none' stroke='%23153d5c' stroke-width='4' d='M2 146.81V2h349.078' /><path fill='none' stroke='%23153d5c' stroke-width='4' d='M29.19 331.858h353.029v-120.99' /><path fill='none' stroke='%23153d5c' stroke-dasharray='11 5' stroke-width='2' d='M363.628 1.767H382.8V106.46' /><path fill='none' stroke='%23153d5c' stroke-dasharray='11 5' stroke-width='2' d='M17.919 332.062H2V246.27' /></svg>");
  border-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384.219 333.859' style='color:%23153d5c'><path fill='none' stroke='currentColor' stroke-width='4' d='M2 146.81V2h349.078M29.19 331.858h353.029v-120.99'/><path fill='none' stroke='currentColor' stroke-dasharray='11 5' stroke-width='2' d='M363.628 1.767H382.8V106.46M17.919 332.062H2V246.27'/></svg>");
  border-image-slice: 25;
  transition: all 0.4s;

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  p {
    padding: 5px 0 0 0;
  }

  &:hover {
    background: $primary;
    transform: scale(1.04);

    &,
    a {
      color: white !important;
    }
  }
}

.wavy-shape {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201920%20899.54%22%3E%3Cpath%20fill%3D%22%23dcdcdc%22%20d%3D%22M1920%2047.89c-104.69-1-200.72%2040.93-289.1%2047.29-134.21%209.66-780.56%20345.42-1165.54%20330.92C85.45%20411.8%20193.93%2098.78%200%200v899.54h1920z%22%2F%3E%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  background-position: bottom center;
}

.carousel-content-wrap {
  background: $primary;

  @include media-breakpoint-up(lg) {
    background: rgba($primary, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    /*
    top: 150px;
    */
    width: 360px;
  }

  /*
  @include media-breakpoint-up(xl) {
    top: 280px;
  }
  */
}

.popover-body {
  ul {
    padding: 0;
    margin: 0 0 0 20px;
  }
}

.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-primary-lighter {
  background-color: #039c96;
}

.bg-primary-light {
  background-color: #005a9c;
}
