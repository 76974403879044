@font-face {
    font-family: 'GT Walsheim Pro Regular';
    src: url('gt-walsheim-pro-regular-webfont.woff2') format('woff2'),
         url('gt-walsheim-pro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gt Walsheim Pro Medium';
    src: url('gt-walsheim-pro-medium-webfont.woff2') format('woff2'),
         url('gt-walsheim-pro-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro Bold';
    src: url('gt-walsheim-pro-bold-webfont.woff2') format('woff2'),
            url('gt-walsheim-pro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}