// Base Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 1.125rem;
$font-weight-base: 300;
$line-height-base: 1.7;

// Headings
$headings-font-family: 'GT Walsheim Pro Medium', serif;
$headings-font-weight: 400;
$headings-margin-bottom: 1.2rem;
$h1-font-size: $font-size-base * 3.5;
$h2-font-size: $font-size-base * 2.4;
$h3-font-size: $font-size-base * 2;

// Buttons
$btn-font-family: 'GT Walsheim Pro Bold';
$btn-font-size: $font-size-base * 1.1;
$btn-font-weight: 400;
$btn-padding-y: 8px;
$btn-padding-x: 30px;

$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

// Dropdown
$dropdown-spacer: 0;

// Colors
$body-color: #4a4a4a;
$body-bg: #e9e9e9;
$primary: #153d5c;
$secondary: #005a9c; // #e9e9e9
$dark: #495057;
$success: #12af4c;
$danger: #e74c3c;
$info: #48708f;
$light: #e2e2e2;

// Grid
// $grid-gutter-width: 4rem;
$grid-gutter-width: 60px;

// Navbar
$navbar-padding-y: 1.25rem;
$navbar-padding-x: 2rem;
$navbar-dark-color: #fff;
$navbar-nav-link-padding-x: 1.5rem;
$navbar-brand-padding-y: 0.5rem;

// Navbar Toggler
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 2rem;
$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29.86 13'%3E%3Ctitle%3Enav-icon%3C/title%3E%3Cg%3E%3Cpath d='M1.94,2H28' fill='%23FFFFFF'/%3E%3Cpath d='M1.93,4h26c2.57,0,2.58-4,0-4h-26C-.64,0-.65,4,1.93,4Z' fill='%23FFFFFF'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M1.94,11H24' fill='%23FFFFFF'/%3E%3Cpath d='M1.93,13H24c2.57,0,2.58-4,0-4H1.93C-.64,9-.64,13,1.93,13Z' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E%0A");

// Spacers
$spacers: (
  6: 5rem,
  7: 6rem,
  8: 7rem,
);

// Misc
$enable-rounded: false;
// $border-radius: 0;
// $border-radius-sm: 0;
// $border-radius-lg: 0;
$enable-responsive-font-sizes: true;
