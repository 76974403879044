.btn-white {
  @include button-variant(
    $background: #fff,
    $border: #fff,
    $hover-background: $primary,
    $active-background: $primary
  );

  color: $primary;

  @include hover() {
    color: #fff;
    background: $primary;
  }
}

.btn-outline-primary:hover svg {
  color: white !important;
}

/*
.btn-outline-light:hover svg {
  color: $primary !important;
}
*/
