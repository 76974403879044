.navbar {
  .nav-link {
    text-transform: uppercase;
    font-family: $headings-font-family;
  }

  .navbar-brand {
    margin-right: 0;
  }

  @include media-breakpoint-down(md) {
    padding: 0;

    .nav-link {
      padding: 0.3rem 0;
    }

    .navbar-collapse {
      padding: 1rem 2rem;
      background: lighten($primary, 3%);
    }

    .dropdown-menu {
      display: block !important;
      background: transparent;
      border-color: transparent;
      padding-top: 0;

      &,
      a,
      a:hover {
        background: transparent;
        color: white;
      }
    }
  }
}
